<template>

	<div>
			
		<div class="container-fluid">
				
			<div class="col-md-4 col-md-offset-4 well-bordered">

				<div class="container-fluid center">
					<img src="/pics/logo.png" 
					style="width: 250px; height: 250px;" class="center" alt="">
				</div>
				
				<h3 class="center">Account Login</h3>
				<hr>

				<table>
					<tr>
						<td>Email</td>
						<td><input type="email" v-model="email" @keyup.enter="accountLogin" class="form-control"></td>
					</tr>

					<tr>
						<td>Password</td>
						<td><input type="password" v-model="password" @keyup.enter="accountLogin" class="form-control"></td>
					</tr>

					<tr>
						<td></td>
						<td>
							<button class="btn btn-primary form-control" @click="accountLogin">Login</button>
						</td>
					</tr>
				</table>
			</div>

		</div>

	</div>

</template>


<script>
	
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default {
		name: 'LoginComponent',

		data() {
			return {
				email: '',
				password : ''
			}
		},

		methods: {
			async accountLogin() {
				if (this.email != "" && this.password != "") {

				await axios.post(this.$store.state.baseApi + "/api/v1/validate/login/credentials", {
                    email: this.email,
                    password: this.password
                }).then( (response) => {
						
						var resData = JSON.parse(JSON.stringify(response.data))
					
						if (resData['status'] == true) {

							this.email = this.password = ''

							this.$store.state.user.firstName = resData['firstName']
							this.$store.state.user.lastName = resData['lastName']

							//Setting Token
							this.$store.commit('successAuth', { 
													'token' : resData['authToken'], 
													'userId' : resData['id'],
													'userLevel' : resData['level'],
													'email': this.email
												} )

							//Redirecting to Dashboard
							this.$router.push('/dashboard')

						}else {
							this.password = ''

							//Toast Wrong Password
							Swal.fire({
								title: 'Error!',
								text: 'Please Enter Correct Email and Password Combination',
								icon: 'warning',
							})
						}


					})


				}else {

					Swal.fire(
						'Warning',
						'Please Enter Email and Password',
						'warning'
						)

				}
			}
		}
	}

</script>