<template>
    <div>
        <nav class="navbar navbar-custom">
            <div class="container-fluid">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar" style="background-color: white;"></span>
                    <span class="icon-bar" style="background-color: white;"></span>
                    <span class="icon-bar" style="background-color: white;"></span>
                </button>
                <router-link class="navbar-brand" to="/">Metals SaaS</router-link>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li class="active" v-if="isAuthenticated">
                        <router-link to="/dashboard"><i class="fa fa-home" aria-hidden="true"></i>
                            &nbsp;Dashboard</router-link>
                    </li>

                    <li v-else>
                        <router-link to="/"><i class="fa fa-home" aria-hidden="true"></i>
                            &nbsp;Home</router-link>
                    </li>

                    <li v-if="isAuthenticated">
                        <router-link to="/metals/prices"><i class="fa fa-usd" aria-hidden="true"></i>
                            &nbsp;Metal Prices
                        </router-link>
                    </li>

                    <li>
                        <router-link to="/about/metals/saas"><i class="fa fa-ravelry" aria-hidden="true"></i>
                            &nbsp;About Metals
                        </router-link>
                    </li>

                 

                </ul>

                <ul class="nav navbar-nav navbar-right" v-if="!isAuthenticated">
                    <li>
                        <router-link to="/auth/login"><i class="fa fa-user-o" aria-hidden="true"></i>
                        &nbsp;Account Login</router-link>
                    </li>
                </ul>

                <ul class="nav navbar-nav navbar-right" v-else>
                    <li>
                        <a href="#"><i class="fa fa-user-o" aria-hidden="true"></i>&nbsp;
                            {{ welcomeText }}
                        </a>
                    </li>

                    <li>
                        <a href="#" @click="logout"><i class="fa fa-share" aria-hidden="true"></i>&nbsp;Logout</a>
                    </li>
                </ul>

                </div>
            </div>
            </nav>
    </div>
</template>


<script>

import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import router from './../../router/index'

    export default {
        name: 'HeaderComponent',
        
        data() {
            return {
                welcomeText: 'Welcome ' + this.$store.state.user.firstName
            }
        },

        methods: {

            async logout() {
                await axios.post(this.$store.state.baseApi + "/api/v1/account/logout", {
                    email: this.email
                }, {
                    headers: {
                        'userId': this.$store.getters.getAuthId,
                        'authToken': this.$store.getters.getAuthToken
                    }
                }).then( (response) => {

                    const resData = JSON.parse(JSON.stringify(response.data))

                    if (resData['status'] == true) {
                        //Logged Out Success
                        this.$store.commit('authLogout')

                        //Redirect to Index Page
                        try{
                            router.push('/')
                        }catch (error) {
                            console.log("Error Occured : " + error.message)
                        }
                        

                    }else {
                        //Failed to Logout - Toast
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to Logout. Please Try Again Later',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }

                } ).catch( (error) => {

                    console.log('Error Occured : ' + error.message)

                    //Logged Out Success
                    this.$store.commit('authLogout')

                    router.push('/')

                })
            }

        },

    
        computed: {
            ...mapGetters(
                ['isAuthenticated', 'getUserLevel']
            )
        },


    }

</script>


<style scoped>
    .black-li {
        color: black;
    }
</style>