<template>
    
    <div>
        
        <div class="container-fluid">

            <div class="">

                <div class="row">
                    <div class="col-sm-6 col-md-4">
                        <div class="thumbnail">
                        <img src="" alt="">
                        <div class="caption">
                            <h3>Metal Prices List</h3>
                            <p>To View Metal Prices press the below button</p>

                            <br/>
                            <p>
                                <a href="/#/metals/prices" class="btn btn-success" style="width: 200px;" role="button">View Metal Prices</a>
                            </p>
                        </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="thumbnail">
                        <img src="" alt="">
                        <div class="caption">
                            <h3>About Metals</h3>

                            <p>To view about metals press the below button</p>
                            
                            <br/>

                            <p>
                                <a href="/#/about/metals/saas" class="btn btn-success" style="width: 200px;" role="button">View About Metals</a>
                            </p>
                        </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="thumbnail">
                        <img src="" alt="">
                        <div class="caption">
                            <h3>My Account</h3>
                            <p>Full Name : {{ this.$store.state.user.firstName }} {{ this.$store.state.user.lastName }} </p>
                            <br/>
                            <p><a href="#" @click="logout()" class="btn btn-danger" style="width: 200px;" role="button">Logout</a></p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="container-fluid row">

                <h3>Activities List</h3>
                <hr/>

                
		<table class="table table-bordered">
			<thead>
				<tr>
					<td class="success">ID</td>
                    <td class="success">Activtity Title</td>
					<td class="success">Activity Info</td>
					<td class="success">Time</td>
				</tr>
			</thead>

        </table>

            </div>

        </div>

    </div>

</template>


<script>

import axios from 'axios'
import Swal from 'sweetalert2'
import router from './../../router/index'

export default ({

    name: 'DashboardComponent',

    methods: {

        
            async logout() {
                await axios.post(this.$store.state.baseApi + "/api/v1/account/logout", {
                    email: this.email
                }, {
                    headers: {
                        'userId': this.$store.getters.getAuthId,
                        'authToken': this.$store.getters.getAuthToken
                    }
                }).then( (response) => {

                    const resData = JSON.parse(JSON.stringify(response.data))

                    if (resData['status'] == true) {
                        //Logged Out Success
                        this.$store.commit('authLogout')

                        //Redirect to Index Page
                        try{
                            router.push('/')
                        }catch (error) {
                            console.log("Error Occured : " + error.message)
                        }
                        

                    }else {
                        //Failed to Logout - Toast
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to Logout. Please Try Again Later',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }

                } ).catch( (error) => {

                    console.log('Error Occured : ' + error.message)

                    //Logged Out Success
                    this.$store.commit('authLogout')

                    router.push('/')

                })
            }

    }


})

</script>
