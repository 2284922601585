<template>
	
	<div>
		
		<div class="container-fluid">
			
		<h4>Metals Prices</h4>
		<hr/>

		<table class="table table-bordered">
			<thead>
				<tr>
					<td class="success">ID</td>
                    <td class="success">Date</td>
					<td class="success">View Prices</td>
					<td class="success">Export to Excel</td>
				</tr>
			</thead>
			
			<tr v-for="(metal, id) in metalsList" v-bind:key="metal.id">
				<td>{{ id + 1 }}</td>
				<td>{{ metal.day_pricing }}</td>
				<td>
					<button class="btn btn-success form-control" @click="viewPricesListOnModal(metal.id, metal.day_pricing)"
							data-toggle="modal" data-target="#metalPricesModal">
						<i class="fa fa-lemon-o" aria-hidden="true"></i>
						&nbsp;View Prices
					</button>
				</td>

				<td>
					<button class="btn btn-primary form-control" @click="exportPricesExcel(metal.id, metal.day_pricing)">
						<i class="fa fa-file-excel-o" aria-hidden="true"></i>
						&nbsp;Export
					</button>
				</td>
				
			</tr>
		</table>

		</div>

		<!-- metalPricesDay -->
		<div class="modal fade" id="metalPricesModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				<h4 class="modal-title" id="myModalLabel">Metal Prices | {{ metalPricesDay }}</h4>
			</div>
			<div class="modal-body">
				
				<table class="table table-bordered">
					<tr>
						<td class="success">ID</td>
						<td class="success">Element Name</td>
						<td class="success">Element Price</td>
						<td class="success">Element Currency</td>
						<td class="success">Element Date</td>
						<td class="success">Element Link</td>
					</tr>

					<tr v-for="(metal, id) in allMetalsList" v-bind:key="metal.id">
						<td class="success">{{ id + 1}}</td>
						<td class="success">{{ ucwords(metal.name) }}</td>
						<td class="success">{{ metal.price }}</td>
						<td class="success">{{ metal.currency }}</td>
						<td class="success">{{ metal.date }}</td>
						<td>
							<a :href="metal.url" target="_blank">Open Link</a>
						</td>
					</tr>
				</table>

			</div>
			<div class="modal-footer">
				
			</div>
			</div>
		</div>
		</div>

	</div>

</template>


<script>
	
	import axios from 'axios'

	export default {
		name: 'MtalPricesComponent',

		data() {
			return {
				metalsList: [],

				metalPricesDay: "",

				allMetalsList: [],

				exportExcelUrl: this.$store.state.baseApi + "/api/v1/get/metals/prices/list/excel/"

			}
		},

		methods: {

			exportPricesExcel(metalId, day_pricing) {
				window.location.assign(this.exportExcelUrl + metalId + "/" + day_pricing, "_blank")
			},

			viewPricesListOnModal(metalId, day_pricing) {
				const indexId = metalId - 1
				this.metalPricesDay = day_pricing

				this.allMetalsList = []
				
				var lastIndex = 40
				var countId = 1
				while (countId != lastIndex) {

					if ( (this.metalsList[indexId]['metal_' + this.doublDigit(countId) ] != null) ) {

						if ( (this.metalsList[indexId]['metal_' + this.doublDigit(countId) ]).length > 10) {
							this.allMetalsList.push(JSON.parse(
								this.metalsList[indexId]['metal_' + this.doublDigit(countId) ]
							))
						}else {
							this.allMetalsList.push({"name":"","price":"","currency":"","date":"", "url":""})
						}
						
					}else {
						this.allMetalsList.push({"name":"","price":"","currency":"","date":"", "url":""})
					}
					
					countId += 1
				}

			},

			doublDigit(countId) {

				countId = countId.toString()

				if (countId.length == 1) {
					countId = "0" + countId
				}

				return countId

			},

			ucwords(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			},

			async getMetalsList() {

				await axios.get(this.$store.state.baseApi + "/api/v1/get/metals/prices/list",  { 
                        
                            headers : {
                                'Content-Type': 'application/json',
                                userId: this.$store.getters.getAuthId,
                                authToken: this.$store.getters.getAuthToken
                            }

                    })
					.then( (response) => {
						
						const resData = response.data
						
						this.metalsListData = resData.data

						this.metalsList = this.metalsListData.data

					})

			}
		},

		created() {
			this.getMetalsList()
		}
	}

</script>