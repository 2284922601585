<template>

    <div>
        
    </div>

</template>

<script>
    

export default {
    name: 'FooterComponent',

}

</script>