import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store/store'

Vue.use(Router)

//Import Components
import IndexComponent from '@/components/pages/IndexComponent.vue'
import DashboardComponent from '@/components/pages/DashboardComponent.vue'
import AboutMetalsComponent from '@/components/pages/AboutMetalsComponent.vue'
import MetalsPricesComponent from '@/components/pages/MetalsPricesComponent.vue'

import LoginComponent from '@/components/auth/LoginComponent.vue'

const authMiddleware = (to, from, next) => {

	console.log(store.state.user.authToken)

	if (store.state.user.authToken != undefined) {
		if ( store.state.user.authToken.length == 0 ) {
			return next('/auth/login')
		}else {
			return next()
		}
		
	}

    return next();
}

//	Router
export default new Router({
	
	mode: 'hash',

	routes: [

		//Metal Prices
		{ path: '/metals/prices', component: MetalsPricesComponent, name: 'metals-prices' },

		//Metals
		{ path: '/about/metals/saas', component: AboutMetalsComponent, name: 'about-metals' },

		//Auth Login
		{ path: '/auth/login', component: LoginComponent, name: 'login' },

		//Index Component
		{ path: '/', component: IndexComponent, name: 'index' },

		//Dashboard Component
		{ path: '/dashboard', component: DashboardComponent, name: 'dashboard', beforeEnter: authMiddleware },

	]

})