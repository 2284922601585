<template>
	
	<div>
		
		<div class="container-fluid">
			
		<h4>Metals List Captured by this SaaS</h4>
		<hr/>

		<table class="table table-bordered">
			<thead>
				<tr>
					<td class="success">ID</td>
					<td class="success">Element</td>
					<td class="success">Symbol</td>
					<td class="success">Currency</td>
				</tr>
			</thead>
			
			<tr v-for="(metal, idA) in metalsList" v-bind:key="metal.id">
				<td>{{ idA + 1 }}</td>
				<td>{{ metal.element }}</td>
				<td>{{ metal.symbol }}</td>
				<td>{{ metal.currency }}</td>
			</tr>
		</table>

		</div>

	</div>

</template>


<script>
	
	import axios from 'axios'

	export default {
		name: 'AboutMetalsComponent',

		data() {
			return {
				metalsList: []
			}
		},

		methods: {
			async getMetalsList() {

				await axios.get(this.$store.state.baseApi + "/api/v1/get/metals/list")
					.then( (response) => {
						
						const resData = response.data
						
						this.metalsList = resData.data

					})

			}
		},

		created() {
			this.getMetalsList()
		}
	}

</script>